import React, { Component } from 'react';
import layoutHelpers from './helpers';
import { SignIn, SignUp, Greetings, Authenticator } from 'aws-amplify-react';
import '../../vendor/libs/react-toastify/react-toastify.scss';
import SignInPage from '../../components/auth/SignIn';
import SignUpPage from '../../components/auth/SignUp';
import { ToastContainer } from 'react-toastify';
import '../../vendor/libs/react-toastify/react-toastify.scss';

class LayoutLiveApp extends Component {
  componentDidMount() {
    // Enshure that we have not '.layout-expanded' class on the html element
    layoutHelpers._removeClass('layout-expanded');

    layoutHelpers.init();
    layoutHelpers.update();
    layoutHelpers.setAutoUpdate(true);
  }

  componentWillUnmount() {
    layoutHelpers.destroy();
  }

  render() {
    return (
      <Authenticator hide={[SignIn, SignUp, Greetings]}>
        <SignInPage />
        <SignUpPage />
        <InnerLayout>{this.props.children}</InnerLayout>
      </Authenticator>
    );
  }
}

class InnerLayout extends Component {
  componentDidMount() {
    // Enshure that we have not '.layout-expanded' class on the html element
    layoutHelpers._removeClass('layout-expanded');

    layoutHelpers.init();
    layoutHelpers.update();
    layoutHelpers.setAutoUpdate(true);
  }

  componentWillUnmount() {
    layoutHelpers.destroy();
  }

  render() {
    return (
      <div>
        {this.props.authState === 'signedIn' && (
          <div className="layout-wrapper layout-1 layout-without-sidenav">
            <div className="layout-inner">
              <div className="ui-bordered p-4">
                <div className="app-brand">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/icon-light.png`}
                    alt="Brand"
                    className="app-brand-logo ui-w-40"
                  />
                  Couchsurvey Live
                </div>
              </div>

              <div className="layout-container">
                <div className="layout-content">
                  <div className="container-fluid flex-grow-1 container-p-y">
                    {this.props.children}
                  </div>
                </div>
              </div>
            </div>
            <ToastContainer closeButton={<ToastCloseButton />} />
          </div>
        )}
      </div>
    );
  }
}

const ToastCloseButton = ({ closeToast }) => (
  <button
    className="Toastify__close-button"
    type="button"
    aria-label="close"
    onClick={closeToast}
  >
    &times;
  </button>
);

export default LayoutLiveApp;
