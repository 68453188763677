import { Auth } from 'aws-amplify';
import posthog from 'posthog-js';

const TRACKING = process.env.REACT_APP_STAGE === 'production';

export const initPosthog = async () => {
  if (TRACKING) {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      posthog.init(process.env.REACT_APP_POSTHOG, {
        api_host: 'https://app.posthog.com',
        loaded: function (posthog) {
          posthog.identify(currentUser.username, {
            email: currentUser.attributes.email,
            subscription: currentUser.attributes['custom:subscription']
          });
        }
      });
    } catch (e) {
      posthog.init(process.env.REACT_APP_POSTHOG, {
        api_host: 'https://app.posthog.com'
      });
    }
  }
};

export const resetPosthog = () => {
  if (TRACKING) {
    posthog.reset();
  }
};

export const trackPosthogEvent = (eventname, properties = {}) => {
  if (TRACKING) {
    posthog.capture(eventname, properties);
  }
};
