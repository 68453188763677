import API from '@aws-amplify/api';
import { Logger } from 'aws-amplify';

const logger = new Logger('lib/stripe');

export const createPortalSession = async (customerId, redirectUrl) => {
  try {
    return await API.post('stripe', '/create-portal-session', {
      body: {
        customerId,
        url: redirectUrl
      }
    });
  } catch (e) {
    logger.error('CreatePortalSession error: ', e);
    throw e;
  }
};

export const createCustomer = async (email, username) => {
  try {
    return await API.post('stripe', '/create-customer', {
      body: { email, username }
    });
  } catch (e) {
    logger.error('createCustomer error: ', e);
    throw e;
  }
};
