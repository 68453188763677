import React, { Component } from 'react';
import { connect } from 'react-redux';

class LayoutFooter extends Component {
  render() {
    return (
      <nav className={`layout-footer footer bg-${this.props.footerBg}`}>
        <div className="container-fluid container-p-x pb-3">
          <a href="/imprint" target="_blank" className="footer-link pt-3">
            Impressum
          </a>
          &nbsp;|&nbsp;
          <a href="/privacy" target="_blank" className="footer-link pt-3">
            Datenschutzerklärung
          </a>
        </div>
      </nav>
    );
  }
}

export default connect(store => ({
  footerBg: store.theme.footerBg
}))(LayoutFooter);
