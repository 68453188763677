import i18n from 'i18next';
import moment from 'moment';
import 'moment/locale/de';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { de, en } from '../locales';

export const i18nInit = () => {
  i18n.on('languageChanged', function (lng) {
    moment.locale(lng);
  });

  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources: {
        en: {
          common: en['en']
        },
        de: {
          common: de['de']
        }
      },

      fallbackLng: 'en',
      ns: ['common'],
      defaultNS: 'common',

      interpolation: {
        escapeValue: false // not needed for react as it escapes by default
      }
    });
};
