import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import LayoutFooter from './LayoutFooter';
import layoutHelpers from './helpers';

const LayoutSurveyApp = props => {
  useEffect(() => {
    // Enshure that we have not '.layout-expanded' class on the html element
    layoutHelpers._removeClass('layout-expanded');

    layoutHelpers.init();
    layoutHelpers.update();
    layoutHelpers.setAutoUpdate(true);

    return () => {
      layoutHelpers.destroy();
    };
  }, []);

  return (
    <div>
      <Helmet>
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
      </Helmet>
      <div className="layout-wrapper layout-1 layout-without-sidenav">
        <div className="layout-inner">
          {props.children}

          <LayoutFooter {...props} />
        </div>
      </div>
    </div>
  );
};

export default LayoutSurveyApp;
