import React, { Component } from 'react';
import layoutHelpers from './helpers';
import { SignIn, SignUp, Greetings, Authenticator } from 'aws-amplify-react';
import '../../vendor/libs/react-toastify/react-toastify.scss';
import SignInPage from '../../components/auth/SignIn';
import SignUpPage from '../../components/auth/SignUp';
import LayoutHorizontalSidenav from './LayoutHorizontalSidenav';

class MembersArea extends Component {
  componentDidMount() {
    // Enshure that we have not '.layout-expanded' class on the html element
    layoutHelpers._removeClass('layout-expanded');

    layoutHelpers.init();
    layoutHelpers.update();
    layoutHelpers.setAutoUpdate(true);
  }

  componentWillUnmount() {
    layoutHelpers.destroy();
  }

  render() {
    return (
      <Authenticator hide={[SignIn, SignUp, Greetings]}>
        <SignInPage />
        <SignUpPage />
        <LayoutHorizontalSidenav>{this.props.children}</LayoutHorizontalSidenav>
      </Authenticator>
    );
  }
}

export default MembersArea;
