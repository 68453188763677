import de from './default.de.json';
import en from './default.en.json';
import layoutsDe from '../shared/layouts/locales/default.de.json';
import layoutsEn from '../shared/layouts/locales/default.en.json';
import appDe from '../components/app/locales/default.de.json';
import appEn from '../components/app/locales/default.en.json';
import authDe from '../components/auth/locales/default.de.json';
import authEn from '../components/auth/locales/default.en.json';
import surveysDe from '../components/surveys/locales/default.de.json';
import surveysEn from '../components/surveys/locales/default.en.json';
import analysisDe from '../components/surveys/analysis/locales/default.de.json';
import analysisEn from '../components/surveys/analysis/locales/default.en.json';
import surveyformDe from '../components/surveys/surveyform/locales/default.de.json';
import surveyformEn from '../components/surveys/surveyform/locales/default.en.json';
import settingsDe from '../components/settings/locales/default.de.json';
import settingsEn from '../components/settings/locales/default.en.json';

de['de'].layouts = layoutsDe['de'];
en['en'].layouts = layoutsEn['en'];
de['de'].app = appDe['de'];
en['en'].app = appEn['en'];
de['de'].auth = authDe['de'];
en['en'].auth = authEn['en'];
de['de'].surveys = surveysDe['de'];
en['en'].surveys = surveysEn['en'];
de['de'].analysis = analysisDe['de'];
en['en'].analysis = analysisEn['en'];
de['de'].surveyform = surveyformDe['de'];
en['en'].surveyform = surveyformEn['en'];
de['de'].settings = settingsDe['de'];
en['en'].settings = settingsEn['en'];

export { de, en };
