import React from 'react';
import { withTranslation } from 'react-i18next';
import { Card, Form, Button, Image } from 'react-bootstrap';
import '../../vendor/styles/pages/authentication.scss';
import { SignIn } from 'aws-amplify-react';

class SignInPage extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ['signIn', 'signedOut', 'signedUp'];
    this.t = props.t;
  }

  signIn = () => {
    return super.signIn();
  };

  showComponent(theme) {
    const { t } = this.props;
    return (
      <div
        className="authentication-wrapper authentication-2 ui-bg-cover ui-bg-overlay-container px-4"
        style={{
          backgroundImage: `url('${process.env.PUBLIC_URL}/img/bg/1.jpg')`
        }}
      >
        <div className="ui-bg-overlay bg-dark opacity-25"></div>

        <div className="authentication-inner py-5">
          <Card>
            <div className="p-4 p-sm-5">
              {/* Logo */}
              <div className="d-flex justify-content-center align-items-center">
                <div className="px-5 mb-4">
                  <Image
                    src={`${process.env.PUBLIC_URL}/img/logo_black.png`}
                    fluid
                  />
                </div>
              </div>
              {/* / Logo */}

              <h5 className="text-center text-muted font-weight-normal mb-4">
                {t('auth.login to your account')}
              </h5>

              {/* Form */}
              <form>
                <Form.Group>
                  <Form.Label>{t('auth.username')}</Form.Label>
                  <Form.Control
                    id="username"
                    key="username"
                    name="username"
                    onChange={this.handleInputChange}
                    type="text"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="d-flex justify-content-between align-items-end">
                    <div>Password</div>
                  </Form.Label>
                  <Form.Control
                    id="password"
                    key="password"
                    name="password"
                    onChange={this.handleInputChange}
                    type="password"
                  />
                  <Form.Label className="d-flex justify-content-between align-items-end">
                    <a
                      href="#d"
                      onClick={() => super.changeState('forgotPassword')}
                      className="d-block small mt-2"
                    >
                      {t('auth.forgot password?')}
                    </a>
                  </Form.Label>
                </Form.Group>

                <div className="d-flex float-right align-items-center m-0">
                  <Button variant="primary" onClick={() => this.signIn()}>
                    {t('auth.sign in')}
                  </Button>
                </div>
              </form>
              {/* / Form */}
            </div>
            <Card.Footer className="py-3 px-4 px-sm-5">
              <div className="text-center text-muted">
                {t('auth.dont have an account yet?')}{' '}
                <Button
                  variant="link"
                  onClick={() => super.changeState('signUp')}
                  style={{ padding: 0 }}
                >
                  {t('auth.sign up')}
                </Button>
              </div>
            </Card.Footer>
          </Card>
        </div>
      </div>
    );
  }
}

export default withTranslation()(SignInPage);
