import React from 'react';
import { withTranslation } from 'react-i18next';
import { Card, Form, Button, Image } from 'react-bootstrap';
import '../../vendor/styles/pages/authentication.scss';
import Auth from '@aws-amplify/auth';
import { createCustomer } from '../../lib/stripe';
import { SignIn } from 'aws-amplify-react';
import { Logger } from 'aws-amplify';
import { trackPosthogEvent } from '../../lib/analytics';

const logger = new Logger('auth/SignUp');

class SignUpPage extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ['signUp'];
    this.state = {
      step: 0,
      username: '',
      password: '',
      email: '',
      authenticationCode: '',
      passwordInvalid: false,
      usernameInvalid: false,
      emailInvalid: false,
      UsernameAvailable: true,
      UsernameError: '',
      customerId: ''
    };
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  validateUsername = e => {
    const { t } = this.props;
    const regexp = /^[a-zA-Z0-9-_]+$/;
    const UsernameValid = e.target.value.search(regexp) === -1 ? false : true;
    if (UsernameValid) {
      this.setState({ UsernameValid });
    } else {
      this.setState({
        UsernameValid,
        UsernameError: t('auth.invalid username error')
      });
    }
    this.setState({ [e.target.name]: e.target.value });
  };

  signUp = async () => {
    const { t } = this.props;
    const { username, password, email } = this.state;
    this.setState({
      usernameInvalid: false,
      emailInvalid: false,
      passwordInvalid: false
    });
    if (username.length === 0) {
      this.setState({
        usernameInvalid: true,
        UsernameError: t('auth.username empty error')
      });
      return;
    } else if (email.length === 0) {
      this.setState({ emailInvalid: true });
      return;
    } else if (password.length < 8) {
      this.setState({ passwordInvalid: true });
      return;
    }

    try {
      const stripeData = await createCustomer(email, username);
      this.setState({ customerId: stripeData.customer.id });
    } catch (err) {
      logger.error('signUp: error signing up to stripe: ', err);
    }

    try {
      await Auth.signUp({
        username,
        password,
        attributes: {
          email,
          'custom:stripeCustomerId': this.state.customerId,
          'custom:subscription': 'free'
        }
      });
      this.setState({ step: 1 });
    } catch (err) {
      if (err.message === 'Invalid email address format.') {
        this.setState({
          usernameInvalid: true,
          UsernameError: t('auth.invalid email adress format error')
        });
      } else if (err.code === 'UsernameExistsException') {
        this.setState({
          usernameInvalid: true,
          UsernameError: t('auth.user exists error')
        });
      }
      logger.error('signUp: error signing up: ', err);
    }
  };

  confirmSignUp = async () => {
    const { username, authenticationCode } = this.state;
    try {
      await Auth.confirmSignUp(username, authenticationCode);
      trackPosthogEvent('couchsurveySignUp');
      super.changeState('signIn');
    } catch (err) {
      logger.log('confirmSignUp: error confirming signing up: ', err);
    }
  };

  showComponent() {
    const { t } = this.props;
    return (
      <div
        className="authentication-wrapper authentication-2 ui-bg-cover ui-bg-overlay-container px-4"
        style={{
          backgroundImage: `url('${process.env.PUBLIC_URL}/img/bg/1.jpg')`
        }}
      >
        <div className="ui-bg-overlay bg-dark opacity-25"></div>

        <div className="authentication-inner py-5">
          {this.state.step === 0 && (
            <Card>
              <div className="p-4 px-sm-5 pt-sm-5">
                {/* Logo */}
                <div className="d-flex justify-content-center align-items-center">
                  <div className="px-5 mb-4">
                    <Image
                      src={`${process.env.PUBLIC_URL}/img/logo_black.png`}
                      fluid
                    />
                  </div>
                </div>
                {/* / Logo */}

                <h5 className="text-center text-muted font-weight-normal mb-4">
                  {t('auth.create an account')}
                </h5>

                {/* Form */}
                <form>
                  <Form.Group>
                    <Form.Label>{t('auth.username')}</Form.Label>
                    <Form.Control
                      id="username"
                      key="username"
                      name="username"
                      onChange={this.validateUsername}
                      type="text"
                      isInvalid={this.state.usernameInvalid}
                    />
                    <div className="invalid-tooltip">
                      {this.state.UsernameError}
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>{t('auth.email')}</Form.Label>
                    <Form.Control
                      id="email"
                      key="email"
                      name="email"
                      onChange={this.onChange}
                      type="email"
                      isInvalid={this.state.emailInvalid}
                    />
                    <div className="invalid-tooltip">
                      {t('auth.email must not be empty')}
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      id="password"
                      key="password"
                      name="password"
                      onChange={this.onChange}
                      type="password"
                      isInvalid={this.state.passwordInvalid}
                    />
                    <div className="invalid-tooltip">
                      {t('auth.password must be at least 8 characters')}
                    </div>
                  </Form.Group>
                  <Button
                    variant="primary"
                    block
                    className="mt-4"
                    onClick={this.signUp}
                  >
                    {t('auth.sign up')}
                  </Button>
                  <div className="text-light small mt-4">
                    {t('auth.tos.by clicking sign up you agree to our')}
                    &nbsp;
                    <a href="/terms/" target="_blank">
                      {t('auth.tos.terms of service')}
                    </a>{' '}
                    {t('auth.tos.and')}{' '}
                    <a href="/privacy/" target="_blank">
                      {t('auth.tos.privacy policy')}
                    </a>
                    .{' '}
                    {t(
                      'auth.tos.we will occasionally send you account related emails'
                    )}
                  </div>
                </form>
                {/* / Form */}
              </div>
              <Card.Footer className="py-3 px-4 px-sm-5">
                <div className="text-center text-muted">
                  {t('auth.already have an account?')}{' '}
                  <Button
                    variant="link"
                    onClick={() => super.changeState('signIn')}
                    style={{ padding: 0 }}
                  >
                    {t('auth.sign in')}
                  </Button>
                </div>
              </Card.Footer>
            </Card>
          )}
          {this.state.step === 1 && (
            <Card>
              <div className="p-4 px-sm-5 pt-sm-5">
                {/* Logo */}
                <div className="d-flex justify-content-center align-items-center">
                  <div className="px-5 mb-4">
                    <Image
                      src={`${process.env.PUBLIC_URL}/img/logo_black.png`}
                      fluid
                    />
                  </div>
                </div>
                {/* / Logo */}

                <h5 className="text-center text-muted font-weight-normal mb-4">
                  {t('auth.confirm your account')}
                </h5>

                <div className="text-light small mt-4">
                  {t('auth.email authentication instructions')}
                  <br />
                  <br />
                </div>

                {/* Form */}
                <form>
                  <Form.Group>
                    <Form.Label>{t('auth.verification code')}</Form.Label>
                    <Form.Control
                      id="authenticationCode"
                      key="authenticationCode"
                      name="authenticationCode"
                      onChange={this.onChange}
                      type="text"
                    />
                  </Form.Group>
                  <Button
                    variant="primary"
                    block
                    className="mt-4"
                    onClick={this.confirmSignUp}
                  >
                    {t('auth.confirm')}
                  </Button>
                </form>
                {/* / Form */}
              </div>
            </Card>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(SignUpPage);
