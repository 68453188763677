/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:506fc69d-da28-4b53-aa6c-bf43f6382802",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_RkGbDiSfq",
    "aws_user_pools_web_client_id": "vqlmrkhdib0b54hgvvtt5df3u",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://u7677o62lrfolmr67z4aszuu6i.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-yo76gi4m75b2pd62vuievvhj7u",
    "aws_cloud_logic_custom": [
        {
            "name": "stripe",
            "endpoint": "https://x6zyg84taf.execute-api.eu-central-1.amazonaws.com/production",
            "region": "eu-central-1"
        }
    ],
    "aws_user_files_s3_bucket": "votycilogostorage194332-production",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
