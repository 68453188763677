import React from 'react';
import loadable from '@loadable/component';
import pMinDelay from 'p-min-delay';
import Loader from './shared/Loader';

// Layouts
import MembersArea from './shared/layouts/MembersArea';
import LayoutSurveyApp from './shared/layouts/LayoutSurveyApp';
import LayoutLiveApp from './shared/layouts/LayoutLiveApp';
import LayoutLegal from './shared/layouts/LayoutLegal';

// Lazy load component
const lazy = cb =>
  loadable(() => pMinDelay(cb(), 200), { fallback: <Loader /> });

// ---
// Default application layout

export const DefaultLayout = MembersArea;

// ---
// Document title template

export const titleTemplate = '%s - couchsurvey';

// ---
// Routes
//
// Note: By default all routes use { "exact": true }. To change this
// behaviour, pass "exact" option explicitly to the route object

export const defaultRoute = '/surveys';
export const routes = [
  {
    path: '/surveys',
    component: lazy(() => import('./components/surveys/List'))
  },
  {
    path: '/surveys/new',
    component: lazy(() => import('./components/surveys/surveyform/Create'))
  },
  {
    path: '/surveys/analyse/:tag',
    component: lazy(() => import('./components/surveys/analysis/Analyse'))
  },
  {
    path: '/surveys/live/:tag',
    component: lazy(() => import('./components/surveys/live/LiveApp')),
    layout: LayoutLiveApp
  },
  {
    path: '/surveys/edit/:tag',
    component: lazy(() => import('./components/surveys/surveyform/Edit'))
  },
  {
    path: '/settings',
    component: lazy(() => import('./components/settings/AccountSettings'))
  },
  {
    path: '/imprint',
    component: lazy(() => import('./components/legal/Imprint')),
    layout: LayoutLegal
  },
  {
    path: '/privacy',
    component: lazy(() => import('./components/legal/Privacy')),
    layout: LayoutLegal
  },
  {
    path: '/terms',
    component: lazy(() => import('./components/legal/Terms')),
    layout: LayoutLegal
  },
  {
    path: '/app/:tag',
    component: lazy(() => import('./components/app/SurveyApp')),
    layout: LayoutSurveyApp
  },
  {
    path: '/app/:tag/:mode',
    component: lazy(() => import('./components/app/SurveyApp')),
    layout: LayoutSurveyApp
  },
  {
    path: '/s/:tag',
    component: lazy(() => import('./components/app/SurveyApp')),
    layout: LayoutSurveyApp
  },
  {
    path: '/s/:tag/:mode',
    component: lazy(() => import('./components/app/SurveyApp')),
    layout: LayoutSurveyApp
  }
];
