import Amplify from 'aws-amplify';

const initLogger = () => {
  if (process.env.REACT_APP_ENV === 'production') {
    Amplify.Logger.LOG_LEVEL = 'ERROR';
  } else if (process.env.REACT_APP_ENV === 'staging') {
    Amplify.Logger.LOG_LEVEL = 'WARN';
  } else {
    Amplify.Logger.LOG_LEVEL = 'INFO';
    window.LOG_LEVEL = 'INFO';
  }
};

export default { initLogger };
