import React, { useEffect } from 'react';
import Router from './shared/Router';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import logger from './lib/logger';
import { i18nInit } from './lib/i18n';
import { initPosthog } from './lib/analytics';

import './vendor/styles/bootstrap.scss';
import './vendor/styles/appwork.scss';
import './vendor/styles/theme-soft.scss';
import './vendor/styles/colors.scss';
import './vendor/styles/uikit.scss';
import './App.scss';

Amplify.configure(awsconfig);
i18nInit();
initPosthog();

const App = () => {
  useEffect(() => {
    logger.initLogger();
  }, []);
  return <Router />;
};

export default App;
